import React                                                  from 'react';
import { graphql }                                            from 'gatsby';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Box } from '@chakra-ui/react';
import Img                                                    from 'gatsby-image';

import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import SEO          from '@interness/web-core/src/components/modules/SEO/SEO';
import Carousel     from '@interness/web-core/src/components/media/Carousel/Carousel';
import MoreExpander from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Lightbox     from '@interness/web-core/src/components/media/Lightbox/Lightbox';

function IndexPage(props) {
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {props.data.headerImages.images.map((image) => (
          <Img fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <Heading subtitle="Herzlich Willkommen">Bilstein Paletten</Heading>
        <section>
          <h3 style={{ textAlign: 'center' }}>Bilstein Paletten GmbH & Co.KG ® ist ein mittelständiges Unternehmen in
            Wuppertal.</h3>
          <p>
            Herzlich Willkommen bei Bilstein Paletten GmbH & Co.KG in Wuppertal. Als mittelständisches Unternehmen
            bieten wir Ihnen von der Beratung über die Fertigung bis hin zum Verkauf, der Reparatur und Vermietung der
            Paletten ein umfassendes und nachhaltiges Konzept. Gegründet 1980, sind wir heute ein schlagkräftiges Team
            von ca. 30 Spezialisten. Unsere Produkte sind nach höchsten Standards gefertigt und garantieren Qualität und
            Zuverlässigkeit.
          </p>
          <MoreExpander>
            <p>
              Unsere Kernkompetenzen liegen in der Produktion und Reparatur von Holzpaletten, die sowohl national als
              auch international verwendet werden. Dank unserer IPPC-Behandlung erfüllen unsere Paletten die strengen
              Anforderungen des internationalen Pflanzenschutzabkommens und sind somit bestens für den Export geeignet.
              Ob Sie neue oder gebrauchte Paletten benötigen, wir bieten Ihnen maßgeschneiderte Lösungen für jede
              Anforderung.
            </p>
            <p>
              Unsere nachhaltigen Palettenlösungen stehen im Einklang mit unserem Engagement für Umweltschutz und
              Ressourcenschonung. Durch die Verwendung von nachhaltig gewonnenem Holz und effizienten
              Produktionsprozessen tragen wir aktiv zum Schutz der Umwelt bei. Unsere Palettenlagerung und unser
              Palettenzubehör runden unser umfassendes Leistungsspektrum ab und bieten Ihnen die Möglichkeit, Ihre
              Logistikprozesse optimal zu gestalten.
            </p>
            <p>
              Unser erfahrenes Team von Spezialisten steht Ihnen jederzeit zur Verfügung, um Sie bei der Auswahl der
              richtigen Paletten zu unterstützen und individuelle Lösungen für Ihre spezifischen Anforderungen zu
              entwickeln. Von der Beratung über die Fertigung bis hin zur Reparatur und Vermietung von Paletten – wir
              bieten Ihnen einen umfassenden Service aus einer Hand.
            </p>
            <p>
              Besonders stolz sind wir auf unsere Sonderpaletten, die nach individuellen Kundenwünschen gefertigt
              werden. Ob spezielle Abmessungen, besondere Materialien oder spezifische Anforderungen – wir finden die
              passende Lösung für Ihre Bedürfnisse. Unsere Industriepaletten und Chemiepaletten sind speziell für die
              hohen Anforderungen der jeweiligen Branchen konzipiert und bieten maximale Sicherheit und Stabilität.
            </p>
            <p>
              Darüber hinaus bieten wir Ihnen die Möglichkeit, Ihre Altholzentsorgung effizient und umweltfreundlich zu
              gestalten. Unser Palettenreparaturservice sorgt dafür, dass Ihre defekten Paletten schnell und
              kostengünstig wieder einsatzbereit sind. Mit unserer modernen Palettenfertigung und unseren flexiblen
              Produktionskapazitäten sind wir in der Lage, auch große Aufträge zeitnah und zuverlässig zu erfüllen.
            </p>
            <p>
              Lassen Sie sich von der Qualität unserer Produkte und unserem umfassenden Service überzeugen. Kontaktieren
              Sie uns noch heute und erfahren Sie mehr über unsere vielfältigen Palettenlösungen und Dienstleistungen.
              Wir freuen uns darauf, gemeinsam mit Ihnen die besten Lösungen für Ihre Logistikanforderungen zu
              entwickeln und umzusetzen.
            </p>
            <p>
              Mit freundlichen Grüßen,<br/>
              Ihr Team von Bilstein Paletten GmbH & Co. KG
            </p>
          </MoreExpander>
        </section>
        <Spacer/>
        <section>
          <Tabs>
            <TabList>
              <Tab>Deutsch</Tab>
              <Tab>English</Tab>
              <Tab>Polski</Tab>
              <Tab>Русский</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <p><b>Ihr Weg zu Bilstein Paletten GmbH & Co. KG ®, Flügel 1, 42369 Wuppertal ( zwischen W-Ronsdorf und
                  Remscheid )
                  Wichtiger Hinweis für Nutzer von Navigationsgeräten, bitte REMSCHEID eingeben!</b></p>
                <p><b>GPS-Daten: 51.20947 - 7.2029613</b></p>
                <MoreExpander>
                  <Flex flexWrap="wrap">
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>Von der ‚A46‘</p>
                      <ul>
                        <li>Ausfahrt 31 ‚Wuppertal-Cronenberg‘ / ‚Wuppertal-Ronsdorf‘</li>
                        <li>Richtung A1 / Ronsdorf / Remscheid (durch ‚Burgholztunnel‘)</li>
                        <li>Nach ca. 5 km an 2. Kreuzungsampel rechts auf die ‚Erbschlöer Straße’</li>
                        <li>Nach ca. 800 m an der 2. Kreuzungsampel links auf die ‚Remscheider Straße’</li>
                        <li>Nach ca. 150 m rechts hoch auf ‚Echorstraße‘</li>
                        <li>Nach ca. 350 m T-Kreuzung ‚Rädchen‘ nach links bis ‚Remscheid Langenhaus‘</li>
                        <li>Nach ca. 300 m links auf ‚Neulandstraße‘ (Wegweiser BILSTEIN-PALETTEN)</li>
                        <li>Nach ca. 300 m BILSTEIN-PALETTEN auf der rechten Seite</li>
                      </ul>
                    </Box>
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>Von der ‚A1‘</p>
                      <ul>
                        <li>Ausfahrt 94 Wuppertal-Ronsdorf von ‚Köln‘ kommend rechts, von ‚Dortmund‘ kommend links</li>
                        <li>Nach ca. 650 m über die ‚B51‘, an der 2. Ampel links auf Parkstraße (über Blombachtalbrücke)
                        </li>
                        <li>Richtung Wuppertal-Ronsdorf bis ca. 750 m an der Ampel links auf Erbschlöer Straße.</li>
                        <li>Nach ca. 800 m an der 2. Kreuzungsampel links auf die ‚Remscheider Straße’</li>
                        <li>Nach ca. 150 m rechts hoch auf Echoerstraße</li>
                        <li>Nach ca. 350 m T-Kreuzung Rädchen nach links bis Remscheid Langenhaus</li>
                        <li>Nach ca. 300 m links auf Neulandstraße (Wegweiser BILSTEIN-PALETTEN)</li>
                        <li>Nach ca. 300 m rechts BILSTEIN-PALETTEN</li>
                      </ul>
                    </Box>
                  </Flex>
                </MoreExpander>
              </TabPanel>
              <TabPanel>
                <p><b>Your way to Bilstein Paletten GmbH & Co. KG ®, Flügel 1, 42369 Wuppertal (between ‚W-Ronsdorf‘ and
                  ‘Remscheid’).
                  Important note: Users of navigation devices, please enter REMSCHEID !</b></p>
                <p><b>GPS: 51.20947 - 7.2029613</b></p>
                <MoreExpander>
                  <Flex flexWrap="wrap">
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>Von der ‚A46‘</p>
                      <ul>
                        <li>Exit 31 ‚Wuppertal-Cronenberg‘ / ‚Wuppertal-Ronsdorf‘</li>
                        <li>Direction A1 / ‚Ronsdorf‘ / ‚Remscheid‘ (trough ‚Burgholztunnel‘)</li>
                        <li>After approx. 5 km at the second traffic light intersection right on ‚Erbschlöer Straße’
                        </li>
                        <li>After approx. 800 m at the second traffic light intersection left on ‚Remscheider Straße’
                        </li>
                        <li>After approx. 150 m right up ‚Echorstraße‘</li>
                        <li>After approx. 350 m at the t-junction ‚Rädchen‘ left until ‚Remscheid Langenhaus‘</li>
                        <li>After approx. 300 m left on the ‚Neulandstraße‘ (Signpost BILSTEIN-PALETTEN)</li>
                        <li>After approx. 300 m BILSTEIN-PALETTEN at the right side</li>
                      </ul>
                    </Box>
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>Von der ‚A1‘</p>
                      <ul>
                        <li>Exit 94 ‚Wuppertal-Ronsdorf‘ right - coming from ‚Cologne‘, left - coming from ‚Dortmund‘
                        </li>
                        <li>After approx. 650 m on the ‚B 51‘ at the second traffic light left on ‚Parkstraße‘ (over the
                          ‚Blombachtalbrücke‘)
                        </li>
                        <li>Direction ‚Wuppertal-Ronsdorf‘ until approx. 750 m at the traffic light left on ‚Erbschlöer
                          Straße‘
                        </li>
                        <li>After approx. 800 m at the second traffic light intersection left on ‚Remscheider Straße’
                        </li>
                        <li>After approx. 150 m right up on ‚Echorstraße‘</li>
                        <li>After approx. 350 m t-junction ‚Rädchen‘ left until ‚Remscheid Langenhaus‘</li>
                        <li>After approx. 300 m left on the ‚Neulandstraße‘ (Signpost BILSTEIN-PALETTEN)</li>
                        <li>After approx. 300 m BILSTEIN-PALETTEN at the right side</li>
                      </ul>
                    </Box>
                  </Flex>
                </MoreExpander>
              </TabPanel>
              <TabPanel>
                <p><b>Tak dojedziesz do Bilstein Paletten GmbH & Co. KG ®, Flügel 1, 42369 Wuppertal ( Pomiędzy
                  Wuppertal-Ronsdorf i Remscheid )
                  Ważna informacja : W nawigację wprowadź REMSCHEID !</b></p>
                <p><b>GPS: 51.20947 - 7.2029613</b></p>
                <MoreExpander>
                  <Flex flexWrap="wrap">
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>Dojazd od autostrady A46</p>
                      <ul>
                        <li>Wyjazd 31 Wuppertal-Cronenberg / Wuppertal-Ronsdorf</li>
                        <li>kierunek na Autostradę A1 / Ronsdorf / Remscheid (przez Tunel: Burgholztunnel).</li>
                        <li>Za około 5 km na 2 skrzyżowaniu z sygnaliyacją świetlną jedź w prawo na ul. Erbschlöer
                          Straße.
                        </li>
                        <li>Po 800 m. Na 2 skrzyżowaniu z sygnaliyacją świetlną w lewo na ul. Remscheider Straße.</li>
                        <li>Za 150 m. W prawo pod górę na ul. Echoerstraße.</li>
                        <li>Po 350 m. Na końcu drogi, w lewo na ul. Rädchen aż do Remscheid- Langenhaus</li>
                        <li>za 300 m. W lewo na ul. Neuland (Kierunkowskaz BILSTEIN-PALETTEN)</li>
                        <li>po 300 m. Jesteś u celu: BILSTEIN-PALETTEN</li>
                      </ul>
                    </Box>
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>Dojazd od autostrady A1</p>
                      <ul>
                        <li>Wyjazd 94 Wuppertal-Ronsdorf jadąc od Kolonji w prawo, od Dortmundu w lewo na drogę krajową
                          B
                          51 (ul. Linde)
                        </li>
                        <li>Po około 650 m. Na 2 skrzyżowaniu z sygnaliyacją świetlną w lewo na ul. Parkstraße ( przez
                          most Blombachtalbrücke nad autostradą) kierunek Wuppertal-Ronsdorf.
                        </li>
                        <li>Po 750 m. Na 2 skrzyżowaniu z sygnaliyacją świetlną w lewo na ul. Erbschlöer Straße.</li>
                        <li>Za 800 m na 2 skrzyżowaniu z sygnaliyacją świetlną w lewo na ul. Remscheider Straße.</li>
                        <li>Po 150 m. W prawo pod górę na ul. Echoerstraße.</li>
                        <li>Za. 350 m. Na końcu drogi, w lewo na ul. Rädchen aż do Remscheid- Langenhaus</li>
                        <li>za 300 w lewo na ul. Neuland (Kierunkowskaz BILSTEIN-PALETTEN)</li>
                        <li>po 300 m jesteś u celu: BILSTEIN-PALETTEN</li>
                      </ul>
                    </Box>
                  </Flex>
                </MoreExpander>
              </TabPanel>
              <TabPanel>
                <p><b>Описание дороги до Bilstein Paletten GmbH & Co. KG ®, Flügel 1, 42369 Wuppertal ( между
                  Wuppertal-Ronsdorf и Remscheid ).
                  Убедительно просим для пользователей навигатора, в навигатор задавать город REMSCHEID!</b></p>
                <p><b>GPS: 51.20947 - 7.2029613</b></p>
                <MoreExpander>
                  <Flex flexWrap="wrap">
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>‚A46‘</p>
                      <ul>
                        <li>Съезд номер 31 ‚Wuppertal-Cronenberg‘ / ‚Wuppertal-Ronsdorf‘.</li>
                        <li>В направлении A1 / Ronsdorf / Remscheid (через ‚Burgholz туннель')</li>
                        <li>Примерно через 5 km на втором светофорном перекрестке повернуть направо на улицу ‚Erbschlöer
                          Straße’.
                        </li>
                        <li>Через ca. 800 m на втором светофорном перекрестке повернуть налево на улицу ‚Remscheider
                          Straße’.
                        </li>
                        <li>Через ca. 150 m повернуть направо вверх на улицу ‚Echorstraße‘.</li>
                        <li>Через ca. 350 m т-образный перекресток ,Rädchen‘ повернуть налево до ‚Remscheid Langenhaus‘.
                        </li>
                        <li>Через ca. 300 m повернуть налево на улицу ‚Neulandstraße‘ (Указатель BILSTEIN-PALETTEN).
                        </li>
                        <li>Через ca. 300 m направо находиться BILSTEIN-PALETTEN.</li>
                      </ul>
                    </Box>
                    <Box width={['100%', '100%', '50%', '50%']}>
                      <p>‚A1‘</p>
                      <ul>
                        <li>Съезд номер 94 ,Wuppertal-Ronsdorf‘, из направления ,Köln' повернуть направо, из направления
                          ,Dortmund' повернуть налево.
                        </li>
                        <li>Через ca. 650 m по ‚B51‘, на втором светофоре повернуть налево на улицу Parkstraße (через
                          Blombachtal мост) в направлении Wuppertal-Ronsdorf.
                        </li>
                        <li>Через ca. 750 m на светофоре повернуть налево на улицу ,Erbschlöer'.</li>
                        <li>Через ca. 800 m на втором светофорном перекрестке повернуть налево на улицу ‚Remscheider
                          Straße’.
                        </li>
                        <li>Через ca. 150 m повернуть направо вверх на улицу ‚Echorstraße‘.</li>
                        <li>Через ca. 350 m т-образный перекресток ,Rädchen‘ повернуть налево до ‚Remscheid Langenhaus‘.
                        </li>
                        <li>Через ca. 300 m повернуть налево на улицу ‚Neulandstraße‘ (Указатель BILSTEIN-PALETTEN).
                        </li>
                        <li>Через ca. 300 m направо находиться BILSTEIN-PALETTEN.</li>
                      </ul>
                    </Box>
                  </Flex>
                </MoreExpander>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </section>
        <Spacer/>
      </Wrapper>
      <section style={{ backgroundColor: '#95a5a6', color: 'white', paddingTop: '50px', paddingBottom: '50px' }}>
        <Wrapper>
          <Heading>Zertifikate</Heading>
          <Lightbox
            thumbnailStyle={{
              padding: '8px',
              backgroundColor: '#fff',
              borderRadius: '4px',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
              '&:hover': {
                boxShadow: 'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px'
              }
            }}
            thumbnailWidth="300px"
            images={props.data.gallery.images}
            thumbnails={props.data.gallery.thumbnails}
            fromApi
          />
        </Wrapper>
      </section>
      <Spacer/>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        gallery: directusMediaCollection(name: {eq: "zertifikate"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1600, quality: 95) {
                                presentationWidth
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            thumbnails: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 300, quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
